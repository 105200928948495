
// if($(window).width() <= 1199 && >= 768 ){
 
// }
(function() {
  'use strict';

  $('.hamburger').on('click', function(e) {
    e.preventDefault();
    $('.hamburger').toggleClass('is-active opened');
    $('.header-wrapper').toggleClass('opened');
    // $("body, .header__top").toggleClass("opened");
  });

  if ( $(window).width() > 768 && $(window).width() < 1199) {
    var nav = priorityNav.init({
     mainNavWrapper: "nav.menu",
   });
  }

  if ( $('.slider').length > 0 ) {
  	$('.slider').slick({
  		infinite: true,
  		dots: true,
  		autoplay: true,
  		autoplaySpeed: 6000,
  		arrows: false,
  		focusOnSelect: false,
  		fade: true,
  		pauseOnHover: false,
      adaptiveHeight: true,
  	})

    $('.slider__left').on('click', function() {
      $('.slider').slick('slickPrev');
    });

    $('.slider__right').on('click', function() {
      $('.slider').slick('slickNext');
    });
  }

  $('.news-slider, .blog-slider').slick({      
      dots: false,      
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 780,
          settings: {
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    })

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  $('.submenu').superfish({
    animation: {height:'show'},
    delay: 1200 
  })

  $("a[href='#feedback'], a[href='#sign-up']").magnificPopup({
    removalDelay: 300,
    type: 'inline',
    mainClass: 'my-mfp-zoom-in'
  });

  $("a[href='#callback'],a[href='#login']").magnificPopup({
    removalDelay: 300,
    type: 'inline',
    mainClass: 'my-mfp-zoom-in my-form',
    alignTop: true,
    callbacks: {
      open: function() {
        $('html').css('margin-right', 0);
      }
    }
  });

  $(".form #phone").inputmask({
    "mask":"+7 (999) 999-99-99",
    "clearIncomplete":true
  });

  $('.zoom-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    gallery: {
      enabled: true,
      tCounter: '<div class="mfp-counters">%curr% из %total%</div>',
    },
    zoom: {
      enabled: true,
      duration: 300, // don't foget to change the duration also in CSS
      opener: function(element) {
        return element.find('img');
      }
    }
    
  });

  $('.product-detail__img').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    closeBtnInside: false,
    fixedContentPos: true,
    mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
    image: {
      verticalFit: true
    },
    zoom: {
      enabled: true,
      duration: 300
    }
  });

  
  $.fn.datepicker.dates['ru'] = {
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
    daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    clear: "Очистить",
    format: "dd.mm.yyyy",
    weekStart: 1,
    monthsTitle: 'Месяцы'
  };

  $('.input-daterange').datepicker({
    language: "ru",
    keyboardNavigation: false,
    forceParse: false,
    format: "dd.mm.yyyy",
});

  /* Переключение */

  let viewTrigger = $('.toggle-cardview').attr('data', 'trigger');
  let cardContainer = $('.product-card');

  viewTrigger.click(function(e) {
    viewTrigger.removeClass('active');
    $(e.target).addClass('active')

    let currentView = $(e.target).attr('data-trigger');
    cardContainer.attr('data-view', currentView);
  })

  //мен для телефона

  $('.header__catalog__togle').click(function(e) {
    $('.header-wrapper').toggleClass('open-catalog');
    $('.header__catalog').toggleClass('header__catalog__togle--open')
    phoneToggle();
  })

  function phoneToggle() {
    $('.has-children a').click(function(e) {
      e.preventDefault();  
      var $this = $(this);

      if ($this.next().hasClass('open-menu')) {
        $this.next().removeClass('open-menu');
        $this.parent().removeClass('open-menu-wrap');
        $this.next().slideUp(200);
      } else {
        $this.parent().parent().find('.has-children ul').removeClass('open-menu');
        $this.parent().parent().find('.has-children').removeClass('open-menu-wrap');

        $this.parent().parent().find('.has-children ul').slideUp(200);
        $this.parent().toggleClass('open-menu-wrap');
        $this.next().toggleClass('open-menu');
        $this.next().slideToggle(200);
      }
    });
  }

  $('.header--phone button.search').on('click', function() {
    $('.search-line').addClass('search-line--open')
  })

   $('.search-line button.close, .search-line .back').on('click', function() {
    $('.search-line').removeClass('search-line--open')
  })



  if ( $(window).width() < 539) {
    $('.footer-menu .toggle').click(function(e) {
      e.preventDefault();  
      var $this = $(this);

      if ($this.next().hasClass('show')) {
        $this.next().removeClass('show');
        $this.next().slideUp(350);
      } else {
        $this.parent().parent().find('.footer-menu ul.basis').removeClass('show');
        $this.parent().parent().find('.footer-menu ul.basis').slideUp(350);
        $this.next().toggleClass('show');
        $this.next().slideToggle(350);
      }
    });
  }

  $('.new-company__head').click(function(e) {
      e.preventDefault();  
      var $this = $(this);

      if ($this.next().hasClass('uncovered')) {
        $this.next().removeClass('uncovered');
        $this.next().slideUp(350);
      } else {
        $this.parent().parent().find('.new-company__body').removeClass('uncovered');
        $this.parent().parent().find('.new-company__body').slideUp(350);
        $this.next().toggleClass('uncovered');
        $this.next().slideToggle(350);
      }
    });

  $(window).scroll(function() {
    if ($('.header-wrapper').hasClass('opened')) {
      return false
    } else if($('.header-wrapper').hasClass('open-catalog')) {
      return false;
    } else {
      $(document).scrollTop() > 65 ? $('.header').addClass('sticky') : $('.header').removeClass('sticky');
      $(document).scrollTop() > 65 ? $('.submenu-wrapper ').addClass('fixed') : $('.submenu-wrapper').removeClass('fixed');
      $(document).scrollTop() > 300 ? $('.header').addClass('sticky-open') : $('.header').removeClass('sticky-open');
      $(document).scrollTop() > 300 ? $('.cd-dropdown').addClass('sticky-open') : $('.cd-dropdown').removeClass('sticky-open');
    }
  });

  $('.show-open').click(function(){
    if( $(this).hasClass('open-eye') ) {
      $('input[name="password"]').attr('type','text');
      $(this).removeClass('open-eye');
    } else {
       $('input[name="password"]').attr('type','password');
       $(this).addClass('open-eye');
    }
  });

  $('.phone-city').click(function(e) {
    e.preventDefault();
    $('.phone-wrapper').addClass('move-out')
  })

  $('.drapdown-city button.close').click(function() {
    $('.phone-wrapper').removeClass('move-out')
  })


  // клонирование элемнетов
  $('a.logo').clone().appendTo('.header--phone .header__head .header__head-block');
  $('header .basket').clone().appendTo('.header--phone .header__head .header__block-left');
  $('header .phone-wrapper').clone(true).appendTo('.header--phone  .header__middle .container');
  $('.header__sign-up').clone(true).appendTo('.header--phone .header__middle');
  $('.header__catalog').clone(true).appendTo('.header--phone .header__middle');
  $('header .menu').clone().appendTo('.header--phone .header__under .header__menu');
  $('header .phone').clone().appendTo('.header--phone .header__under .header__block');
  $('header a[href="#callback"]').clone(true).appendTo('.header--phone .header__under .header__block');
  $('header .group-link').clone().appendTo('.header--phone .header__under .header__block');
  // $('.cd-dropdown').clone(true).addClass('nav-phone').removeClass('cd-dropdown').appendTo('.navigation-phone');
  $('.cd-dropdown').clone(true).addClass('nav-phone').appendTo('.navigation-phone');

  $('.toggle-menu').on("click", function(e) {
    e.preventDefault();
    toggleMenu();
  })


  function toggleMenu() {
    $('.cd-dropdown').toggleClass('cd-dropdown-is-active')
  }

  var submenuDirection = ( !$('.cd-dropdown-wrapper').hasClass('open-to-left') ) ? 'right' : 'left';
  $('.cd-dropdown-content').menuAim({
    activate: function(row) {
      $(row).children().addClass('is-active').removeClass('fade-out');
      if( $('.cd-dropdown-content .fade-in').length == 0 ) $(row).children('ul').addClass('fade-in');
    },
    deactivate: function(row) {
      $(row).children().removeClass('is-active');
      if( $('li.has-children:hover').length == 0 || $('li.has-children:hover').is($(row)) ) {
        $('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
        $(row).children('ul').addClass('fade-out')
      }
    },
    exitMenu: function() {
      $('.cd-dropdown-content').find('.is-active').removeClass('is-active');
      return true;
    },
    submenuDirection: submenuDirection,
  }); 
})();


$(document).on('click', '.collapse-inner .panel-heading:not([id]) a', function(e) {
  e.preventDefault();

  $(this).parents('.panel').siblings().find('.panel-heading a').addClass('collapsed');
  $(this).parents('.panel').siblings().find('.panel-collapse').slideUp(200);

  if ($(this).hasClass('collapsed')) {
    $(this).parents('.panel').find('.panel-collapse').slideDown(200).addClass('in');
    $(this).removeClass('collapsed');
  } else {
    $(this).parents('.panel').find('.panel-collapse').slideUp(200).removeClass('in');
    $(this).addClass('collapsed');
  }
});




